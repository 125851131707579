#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 0, 0, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.user-avatar {
  justify-content: left;
}

.button_ordenar {
  display: inline-block;
  border-radius: 4px;
  background-color: #5c697c;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding: 3px;
  width: 35px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  box-shadow: 1px 2px #999;
}

.button {
  display: inline-block;
  border-radius: 4px;
  background-color: #5c697c;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding: 8px;
  width: 80px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  box-shadow: 1px 2px #999;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.tox-statusbar__branding {
  display: none;
}
