.content-main {
  border-radius: 21px 21px 21px 21px;
  -moz-border-radius: 21px 21px 21px 21px;
  -webkit-border-radius: 21px 21px 21px 21px;
  border: 0px solid #000000;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b6b6b6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7e7b7b;
}

.custom-row {
  display: flex;
  flex-wrap: nowrap; /* Para evitar que las columnas se envuelvan */
}

.custom-col {
  min-width: 0; /* Para asegurar que las columnas no crezcan más allá del contenedor */
}
